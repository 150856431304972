import { LayoutGroup, motion } from "framer-motion";
import { useModalScrollbar } from "hooks/useFreeze";
import { useRouter } from "next/router";
import { useCallback, useEffect, useState } from "react";
import { teamData, roles } from "store/allData";
import { badgeType, teamMemberType } from "types/allTypes";
import { framerProps } from "utils/util";
import Filters from "../Filters";
import MemberCard from "./MemberCard";
import _values from "lodash/values";
import LoadMore from "components/common/LoadMore";
import Heading from "components/common/Heading";
import SectionWrapper from "components/common/SectionWrapper";

interface props {
  shuffledTeam: teamMemberType[];
}
const projectTypeArray: badgeType[] = _values(roles);

const Team: React.FC<props> = ({ shuffledTeam }) => {
  const [typeFilter, setTypeFilter] = useState("All");
  const [num, setNum] = useState(8);

  const filterClickHandler = useCallback((data: badgeType) => {
    setNum(teamData.length);
    setTypeFilter(data.name);
  }, []);

  const sortedTeamData = shuffledTeam
    .filter((el) => el)
    .sort((a, b) => {
      if (typeFilter === "" || typeFilter === "All") return 0;
      if (a.roleType.name === typeFilter) {
        return -1;
      } else {
        return 1;
      }
    });

  return (
    <SectionWrapper className="mx-auto w-full">
      <div
        id="team"
        className="w-full ssm:w-8/12 sm:w-11/12 md:w-9/12 lg:w-10/12 xl:w-10/12 mx-auto
     flex flex-col justify-center items-center py-24 relative"
      >
        <Heading
          className="text-5xl sm:text-[40px] !leading-[55px] mb-10"
          bgPosition="0% 50%"
        >
          Our Team
        </Heading>
        {/* <div className="w-full md:w-2/3">
          <Filters
            typeFilter={typeFilter}
            filterClickHandler={filterClickHandler}
            projectTypeArray={projectTypeArray}
          />
        </div> */}
        <LayoutGroup>
          <motion.ul
            className="w-full grid gap-4 mt-10 justify-center"
            style={{ gridTemplateColumns: "repeat(auto-fit,283px)" }}
          >
            {sortedTeamData.map((data, index) => {
              if (index + 1 > num) return null;
              return <MemberCard key={data.id} data={data} index={index} />;
            })}
          </motion.ul>
        </LayoutGroup>
        <LoadMore
          className="absolute -bottom-5 z-100 "
          initial={6}
          addPerClick={6}
          num={num}
          fullLength={teamData.length}
          setNum={setNum}
        />
      </div>
    </SectionWrapper>
  );
};

export default Team;

import Badge from "components/common/Badge";
import { badgeType } from "types/allTypes";

interface props {
  data: badgeType;
  typeFilter: string;
  filterClickHandler: (data: badgeType) => void;
}

const EachFilter: React.FC<props> = ({ data, typeFilter, filterClickHandler }) => {
  const clickHandler = () => {
    filterClickHandler(data);
  };
  return (
    <button
      onClick={clickHandler}
    >
      <Badge
        color={ typeFilter === data.name ? "rgba(126,92,255,1)" : "rgba(126,92,255,0)"}
        intensity={10}
        dotBrightness={1.5}
        textColor={typeFilter === data.name ? "rgb(255 255 255)" : "rgba(129, 127, 134, 1)"}
        borderColor="rgba(66, 74, 81, 0.20)"
      ><span className="text-lg select-none">
        {data.name}
        </span>
        </Badge>
    </button>
  );
};

export default EachFilter;

import { Dispatch, SetStateAction } from "react";
import Button from "../Button";

interface props {
  num: number;
  setNum: Dispatch<SetStateAction<number>>;
  initial: number;
  addPerClick: number;
  fullLength: number;
  className?:string;
}

const LoadMore: React.FC<props> = ({
  num,
  setNum,
  initial,
  fullLength,
  addPerClick,
  className
}) => {
  const clickHandler = () => {
    setNum(prevState => prevState + addPerClick);
  };
  if (initial >= fullLength || num >= fullLength) return null;
  return (
    <Button size={2} variant="outline" clickHandler={clickHandler} className={className}>
      Load More
    </Button>
  );
};

export default LoadMore;

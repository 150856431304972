
interface Props extends React.HTMLAttributes<HTMLDivElement> {
    children: React.ReactNode;
}

const SectionWrapper: React.FC<Props> = ({ children,...rest }) => {
  return <div {...rest} style={{ maxWidth: 1536 }}>{children}</div>;
};

export default SectionWrapper;

import { commentsData } from "store/allData";
import EachComment from "./EachComment";
import SectionWrapper from "components/common/SectionWrapper";

const Comments: React.FC = () => {
  return (
    <div className="w-full  mx-auto flex flex-col justify-center items-center my-20 pt-10 pb-20 bg-neutral-90/20">
      <SectionWrapper className="w-full mx-auto flex flex-col justify-center items-center">
      <h2 className="text-3xl font-semibold">People are talking...</h2>
      <ul className="w-11/12 ssm:w-10/12 sm:w-11/12 lg:w-11/12 xl:w-10/12 mt-10 columns-1 md:columns-2 xl:columns-3" >
        {commentsData.map((data,index) => {
          return <EachComment key={data.name+index} {...data} />;
        })}
      </ul>
    </SectionWrapper>
    </div>
  );
};

export default Comments;

import { projectsData, teamData } from "store/allData";
import { projectType, teamMemberType } from "types/allTypes";
import Benefits from "./Benefits";
import Comments from "./Comments";
import ContactUs from "./ContactUs";
import Hero from "./Hero";
import Projects from "./Projects";
import Tabs from "./Tabs";
import Team from "./Team";
import CallToAction from "./CallToAction";

interface props {
  teamDataGridOrder: number[];
  projectsDataGridOrder: number[];
}

const HomePage: React.FC<props> = ({
  teamDataGridOrder,
  projectsDataGridOrder,
}) => {
  const shuffledProjects: projectType[] = projectsDataGridOrder.map((order) => {
    return {
      order,
      ...projectsData[order],
    };
  });
  const shuffledTeam: teamMemberType[] = teamDataGridOrder.map((order) => {
    return {
      order,
      ...teamData[order],
    };
  });

  return (
    <div className="w-full h-full flex flex-col">
      <Hero />
      <Benefits />
      <Projects shuffledProjects={shuffledProjects} />
      <Team shuffledTeam={shuffledTeam} />
      <Comments />
      <CallToAction />
      <ContactUs />
    </div>
  );
};

export default HomePage;

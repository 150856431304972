import { motion } from "framer-motion";
import { useResizeHandler } from "hooks/useResizeHandler";
import {
  MutableRefObject,
  RefObject,
  useEffect,
  useRef,
  useState,
} from "react";

interface props {
  children: React.ReactNode;
  gap?: number;
}
const Carousel: React.FC<props> = ({ children, gap = 0.5 }) => {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <motion.div ref={ref} className="flex overflow-hidden px-1 py-px">
      <motion.ul
        className="flex justify-start items-center"
        style={{ gap: gap + "rem" }}
        drag="x"
        dragConstraints={ref}
      >
        {children}
      </motion.ul>
    </motion.div>
  );
};

export default Carousel;

import Social from "components/common/Social";
import { motion } from "framer-motion";
import Image from "next/image";
import { teamMemberType } from "types/allTypes";
import { teamAnim } from "utils/animation";
import { framerProps } from "utils/util";

interface props {
  data: teamMemberType;
  index: number;
}
const MemberCard: React.FC<props> = ({ data, index }) => {
  return (
    <motion.li
      layout
      {...framerProps}
      custom={index % 9}
      variants={teamAnim}
      className="group w-[283px] h-[410px] rounded-lg overflow-hidden"
    >
      <div className="w-full h-full pt-[30px] flex flex-col justify-start items-center font-heading bg-[rgba(250,250,250,1)] hover:bg-[rgba(126,92,255,1)] text-[#18171B] hover:text-white duration-300 ">
        <span className="w-[200px] min-h-[200px] h-[200px] relative rounded-full overflow-hidden ">
          <Image
            src={data.image}
            quality={100}
            width={200}
            height={200}
            alt={data.name + " " + data.role + " spila"}
            placeholder="blur"
            className="group-hover:scale-105 duration-500 ease-in-out object-cover"
            sizes="16rem"
          />
        </span>
        <div className="px-5 text-center">
          <h4 className="text-[22px] font-bold mt-5">{data.name}</h4>
          <h4 className="text-sm font-medium mt-2">{data.role}</h4>
        </div>
        <ul className="flex justify-center items-center gap-2 mt-auto mb-7">
          {data.social.map((social, index) => {
            return (
              <Social
                key={data.name + data.id + index}
                data={social}
                hasTransition={true}
                width={"40px"}
                svgWidth={"20px"}
              />
            );
          })}
        </ul>
      </div>
    </motion.li>
  );
};

export default MemberCard;

import { ReactPortal, useLayoutEffect, useState, ReactNode } from "react";
import { createPortal } from "react-dom";

interface props {
  id: string;
  children: any;
}
const Portal = ({ id, children }: props) => {
  const [node, setNode] = useState<HTMLElement | null>();

  useLayoutEffect(() => {
    const nodeElement = document.getElementById(id);
    setNode(nodeElement);
  }, [id, setNode]);

  if (!node) {
    return null;
  }
  return <>{createPortal(children, node)}</>;
};

export default Portal;

import { motion, useAnimation } from "framer-motion";
import { useState } from "react";
import { benefitType } from "types/allTypes";
import { benefitSvgAni } from "utils/animation";

interface props {
  data: benefitType;
}

const EachBenefit: React.FC<props> = ({ data }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const controls = useAnimation();

  const animationStartHandler = () => {
    if (isPlaying === false) {
      controls.start("shake");
      setIsPlaying(true);
    }
  };

  return (
    <motion.li
      className="w-72 h-[300px] flex flex-col justify-start items-center bg-white ring-1
       ring-[#E2E2E2] duration-300 rounded-[26px] hover:shadow-lg hover:ring-transparent
       "
       onHoverStart={animationStartHandler}
       onTap={animationStartHandler}
    >
      <motion.span
        initial="hidden"
        animate={controls}
        variants={benefitSvgAni}
        onAnimationComplete={() => setIsPlaying(false)}
        className="w-[70px] h-[70px] mt-11"
      >
        {data.icon}
      </motion.span>
      <h3 className="text-base font-bold mt-7 text-slate-600">{data.title}</h3>
      <p className="w-full mt-3 text-center px-5 text-sm font-normal text-slate-600 ">
        {data.desc}
      </p>
    </motion.li>
  );
};

export default EachBenefit;

import { HTMLAttributes } from "react";

interface Props {
  children: React.ReactNode;
  className:string;
  bgPosition:string;
}

const Heading: React.FC<Props> = ({ children,className,bgPosition }) => {
  return (
    <h2
      className={`flex items-start justify-start font-black text-transparent font-heading ${className}`}
      style={{
        WebkitBackgroundClip: "text",
        backgroundClip: "text",
        backgroundColor: "white",
        backgroundImage: "url('/text-bg.png')",
        backgroundSize: "100%",
        backgroundPosition: bgPosition,
      }}
    >
      {children}
    </h2>
  );
};

export default Heading;

import LoadMore from "components/common/LoadMore";
import { AnimatePresence, LayoutGroup, motion } from "framer-motion";
import { useModalScrollbar } from "hooks/useFreeze";
import { useRouter } from "next/router";
import { useCallback, useEffect, useState } from "react";
import { projects, projectsData, projectsTabData } from "store/allData";
import { framerProps } from "utils/util";
import Filters from "../Filters";
import Tabs from "../Tabs";
import EachTab from "../Tabs/EachTab";
import EachProject from "./EachProject";
import ProjectModal from "./ProjectModal";
import _values from "lodash/values";
import { badgeType, projectType } from "types/allTypes";
import Heading from "components/common/Heading";
import SectionWrapper from "components/common/SectionWrapper";

const projectTypeArray: badgeType[] = _values(projects);

interface props {
  shuffledProjects: projectType[];
}

const Projects: React.FC<props> = ({ shuffledProjects }) => {
  const [activeTab, setActiveTab] = useState(0);
  const router = useRouter();
  const [num, setNum] = useState(6);
  const [isOpen, setIsOpen] = useState(false);
  useModalScrollbar(isOpen);
  const [typeFilter, setTypeFilter] = useState("All");

  useEffect(() => {
    if (!!router.query.project) {
      setIsOpen(true);
    }
  }, [router.query.project]);

  const activeProjectsData = shuffledProjects
    .filter((item) => true)
    .sort((a, b) => {
      if (typeFilter === "" || typeFilter === "All") return 0;
      let isFiltered = false;
      a.type.forEach(item=>{
        if(item.name===typeFilter){
          isFiltered = true;
        } 
      })
      return isFiltered ? -1 : 1
    });

  const filterClickHandler = useCallback((data: badgeType) => {
    setNum(activeProjectsData.length);
    setTypeFilter(data.name);
  }, []);

  return (
    <div
      id="projects"
      className="w-full mx-auto flex flex-col justify-start items-center bg-neutral-90/20 pt-16 pb-28 relative font-heading"
    >
      <SectionWrapper className="w-full mx-auto flex flex-col justify-start items-center relative">
        <Heading
          className="text-5xl sm:text-[40px] !leading-[55px] mb-10"
          bgPosition="0% 50%"
        >
          Our Projects
        </Heading>
        {/* <Tabs>
          {projectsTabData.map((data, index) => {
            return (
              <EachTab
                key={data.name}
                data={data}
                index={index}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />
            );
          })}
        </Tabs> */}
        <div className="w-11/12 md:w-2/3">
          <Filters
            typeFilter={typeFilter}
            filterClickHandler={filterClickHandler}
            projectTypeArray={projectTypeArray}
          />
        </div>
        <LayoutGroup>
          <motion.ul
            className="w-11/12 ssm:w-9/12 sm:w-11/12 md:w-10/12 lg:w-11/12 xl:w-10/12 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 
          justify-center gap-5 auto-rows-fr p-5 mt-5"
          >
            {activeProjectsData.map((data, index) => {
              if (index + 1 > num) return null;

              return <EachProject key={data.id} data={data} index={index} />;
            })}
          </motion.ul>
          <ProjectModal setIsOpen={setIsOpen} isOpen={isOpen} />
        </LayoutGroup>
        <LoadMore
          className="absolute bottom-[-80px] z-100 "
          initial={6}
          addPerClick={6}
          num={num}
          fullLength={activeProjectsData.length}
          setNum={setNum}
        />
      </SectionWrapper>
    </div>
  );
};

export default Projects;

import { ContactSVG, SmsSVG, SupportEmailSVG } from "assets/svg";
import { contactList, contactUsData, teamData } from "store/allData";
import Contact from "./Contact";
import Heading from "components/common/Heading";
import Button from "components/common/Button";
import Social from "components/common/Social";
import { ChangeEventHandler, useState } from "react";
import { toast } from "react-toastify";
interface State {
	name: string;
	email: string;
	message: string;
	company: string;
}

type StateKey = keyof State;

const getDefaultState = () => ({
	company: "",
	email: "",
	message: "",
	name: "",
});

const ContactUs: React.FC = () => {
	const [state, setState] = useState(getDefaultState);
	const [loading, setLoading] = useState(false);

	const handleChange: ChangeEventHandler<
		HTMLInputElement | HTMLTextAreaElement
	> = ({ target: { name, value } }) => {
		setState({
			...state,
			[name as StateKey]: value,
		});
	};

	const handleSubmit = async () => {
		setLoading(true);

		try {
			await fetch(`/api/contact-us`, {
				body: JSON.stringify({
					form: state,
				}),
				method: "post",
				headers: {
					"Content-Type": "application-json",
				},
			});

			toast.success(
				"Submitted successfully, We will get back to you as soon as possible!",
			);
			setState(getDefaultState);
		} catch (error) {
			if (
				typeof error === "object" &&
				error !== null &&
				"message" in error &&
				typeof error.message === "string"
			)
				toast.error(error.message);
		} finally {
			setLoading(false);
		}
	};

	const isSubmitDisabled = ![state.email, state.message, state.name].every(
		Boolean,
	);

	return (
		<div
			id="contact-us"
			className="w-full h-full flex flex-col justify-center items-center mt-28 mb-20 font-heading"
		>
			<Heading
				className="text-5xl sm:text-[40px] !leading-[55px]"
				bgPosition="0% 50%"
			>
				Contact Us
			</Heading>
			<p className="text-lg font-normal  mt-6 px-3 text-center">
				Do you wish to hire Spila for your product? feel free to contact us.
			</p>

			<div className="w-full mt-10 flex-col gap-20 justify-between align-top md:w-[320px] md:p-0 p-5">
				<Input
					value={state.name}
					onChange={handleChange}
					id="name"
					name="name"
					label="Name"
					required
				/>
				<Input
					value={state.email}
					onChange={handleChange}
					id="email"
					name="email"
					label="Email"
					required
				/>
				<Input
					value={state.company}
					onChange={handleChange}
					id="company"
					name="company"
					label="Company"
				/>

				<label
					htmlFor="message"
					className="block mb-2 text-sm font-medium text-gray-900  "
				>
					Message
				</label>
				<textarea
					value={state.message}
					onChange={handleChange}
					id="message"
					name="message"
					className="w-full resize-none !h-40  transition-all duration-150  bg-gray-50  mt-2 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-50 focus:border-primary-50 block p-2.5"
					required
				/>

				<Button
					disabled={isSubmitDisabled || loading}
					clickHandler={handleSubmit}
					variant={loading ? "secondary" : "primary"}
					size={1.5}
					className="w-full  mt-2"
				>
					{loading ? "Sending..." : "Submit"}
				</Button>
			</div>

			<a
				href="mailto:support@spila.dev"
				className="flex gap-1.5 mt-14 items-center cursor-pointer"
			>
				<SmsSVG className="w-6 h-6" />
				<SupportEmailSVG />
			</a>
			<div className="flex justify-center items-center gap-4 mt-7">
				{contactUsData.map((social) => {
					return (
						<Social
							key={social.href}
							data={social}
							hasTransition={true}
							svgWidth="27px"
							width="55px"
							isSquare
						/>
					);
				})}
			</div>
		</div>
	);
};

export default ContactUs;

type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
	label: string;
};

const Input: React.FC<InputProps> = ({ label, ...rest }) => {
	return (
		<div className=" mt-2">
			<label
				htmlFor="first_name"
				className="block mb-2 text-sm font-medium text-gray-900  "
			>
				{label}
			</label>

			<input
				type="text"
				{...rest}
				className="w-full transition-all duration-150  bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-50 focus:border-primary-50 block p-2.5"
				required
			/>
		</div>
	);
};

// const ContactUs: React.FC = () => {
//   return (
//     <div id="contact-us" className="w-full h-full flex flex-col justify-center items-center mt-28">
//       <h2 className="text-3xl font-bold">Contact our experts</h2>
//       <div className="w-full xl:w-10/12 h-full flex flex-col md:flex-row justify-center items-center  mb-20 mt-10">
//         <div className="w-11/12 ssm:w-2/3  md:w-1/2 md:pr-20 md:pt-8 h-full flex flex-col md:self-start text-lg font-normal">
//             <p>
//             Do you wish to hire Spila for your product? feel free to contact us with any of these ways:
//             </p>
//             <span className="w-full m-auto" >
//             <ContactSVG />
//         </span>
//         </div>
//         <ul className="flex flex-col justify-start items-start gap-1">
//           {contactList.map((data) => {
//             return <Contact key={data.name} data={data} />;
//           })}
//         </ul>
//       </div>
//     </div>
//   );
// };

// export default ContactUs;

import { bgImg1, heroImg } from "assets/img";
import Button from "components/common/Button";
import Heading from "components/common/Heading";
import SectionWrapper from "components/common/SectionWrapper";
import Image from "next/image";
import { useRouter } from "next/router";

const Hero: React.FC = () => {
  const router = useRouter();
  return (
    <div className="relative flex justify-center w-[calc(100%-100px)] lg:w-[calc(100%-250px)] mx-auto mt-16 lg:mt-36 z-10">
      <SectionWrapper className="relative flex flex-col-reverse ssm:flex-col md:flex-row justify-center w-full mx-auto">
        <div className="z-20 flex flex-col items-center">
          <div className="flex flex-col max-w-[608px]">
            <Heading
              className="text-3xl sm:text-[40px] !leading-[55px]"
              bgPosition="0% 190%"
            >
              AFFORDABLE BRILLIANCE,
              <br />
              IN WEB3 DEVELOPMENT
            </Heading>
            <p className="w-full font-medium text-black text-lg md:text-xl !leading-10 mt-6">
              Spila leverages the global talent pool in offering development
              services using cutting edge innovation at unmatched prices.
            </p>
            <div className="flex items-start justify-start w-full gap-2 mt-5 flex-row lg:mt-16">
              <Button
                size={2}
                variant="primary"
                clickHandler={() => router.push("#contact-us")}
                className="font-bold font-heading"
              >
                Contact us
              </Button>
              <Button
                size={2}
                variant="outline"
                clickHandler={() => router.push("#benefits")}
                className="font-bold font-heading"
              >
                Learn more
              </Button>
            </div>
          </div>
        </div>
        <div className="z-10 self-center -mt-24 ssm:mt-0 max-w-[350px] md:max-w-[550px]">
          <Image
            className="z-10"
            alt="spila"
            width={544}
            height={490}
            src={heroImg}
            quality={100}
          />
        </div>
      </SectionWrapper>
      <div className="absolute w-screen h-full overflow-hidden z-0 py-[430px] -mt-[300px] ">
        <Image
          src={bgImg1}
          fill
          alt="spila"
          quality={100}
          className="object-cover"
        />
      </div>
    </div>
  );
};

export default Hero;


// shimmer effect for image placeholder
export const shimmer = (w: number, h: number): string => `
<svg width="${w}" height="${h}" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <defs>
    <linearGradient id="g">
      <stop stop-color="#333" offset="20%" />
      <stop stop-color="#222" offset="50%" />
      <stop stop-color="#333" offset="70%" />
    </linearGradient>
  </defs>
  <rect width="${w}" height="${h}" fill="#333" />
  <rect id="r" width="${w}" height="${h}" fill="url(#g)" />
  <animate xlink:href="#r" attributeName="x" from="-${w}" to="${w}" dur="1s" repeatCount="indefinite"  />
</svg>`;

export const toBase64 = (str: string): string =>
  typeof window === "undefined"
    ? Buffer.from(str).toString("base64")
    : window.btoa(str);

export const delay = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};
// export const repeater = async (list:[]) {
//   for (item of list) {
//     console.log(item);
//     await delay(2000);
//   }
// }



export const secondsToDhms = (seconds:number, dhms:"dhms"|"dhm") => {
  seconds = Number(seconds);
  let d = Math.floor(seconds / (3600 * 24));
  let h = Math.floor((seconds % (3600 * 24)) / 3600);
  let m = Math.floor((seconds % 3600) / 60);
  let s = Math.floor(seconds % 60);
  let dDisplay = d > 0 ? d + "d " : "";
  let hDisplay = h > 0 ? h + "h " : "";
  let mDisplay = m > 0 ? m + "min " : "";
  let sDisplay = s > 0 ? s + "s" : "";
  if (dhms === "dhms") {
    return dDisplay + hDisplay + mDisplay + sDisplay;
  } else if (dhms === "dhm") {
    return dDisplay + hDisplay + mDisplay;
  }
};


export const framerProps = {initial:"hidden",animate:"visible",exit:"out"};

export const statusConvertor = (code:number):string =>{
  let result = "finished";
switch (code) {
  case 0:
    result= "finished";
    break;
  case 1:
    result= "in review";
    break;
  case 2:
    result= "in progress";
    break;

  default: "finished"
    break;
}
return result;
}


export const hexToRgbA = (hex:string,opacity:number=1) =>{
  let c:any;
  if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
      c= hex.substring(1).split('');
      if(c.length== 3){
          c= [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c= '0x'+c.join('');
      return `rgba(${[(c>>16)&255, (c>>8)&255, c&255].join(',')},${opacity})`;
  }
  throw new Error('Bad Hex');
}


export const shuffle = <T>(array:T[]):T[] => {
  let currentIndex = array.length;
  let randomIndex:number;

  // While there remain elements to shuffle.
  while (currentIndex != 0) {

    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return [...array];
}
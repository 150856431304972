import Carousel from "components/common/Carousel";
import { motion } from "framer-motion";
import { Dispatch, SetStateAction, useRef } from "react";
import { badgeType } from "types/allTypes";
import EachFilter from "./EachFilter";

interface props {
  typeFilter: string;
  filterClickHandler: (data: badgeType) => void;
  projectTypeArray: badgeType[];
}

const Filters: React.FC<props> = ({
  typeFilter,
  projectTypeArray,
  filterClickHandler,
}) => {
  return (
    <motion.div className="w-full mx-auto h-12 mt-5 flex justify-center items-center overflow-hidden">
      <Carousel>
        {projectTypeArray.map((type) => {
          return (
            <EachFilter
              key={type.name}
              data={type}
              typeFilter={typeFilter}
              filterClickHandler={filterClickHandler}
            />
          );
        })}
      </Carousel>
    </motion.div>
  );
};

export default Filters;

import Badge from "components/common/Badge";
import { motion, useMotionValue } from "framer-motion";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { projectType } from "types/allTypes";
import { projectAnim } from "utils/animation";
import { framerProps } from "utils/util";

interface props {
	data: projectType;
	index: number;
}

const EachProject: React.FC<props> = ({ data, index }) => {
	const router = useRouter();
	const zIndex = useMotionValue(0);
	useEffect(() => {
		if (router.query.project === data.name + data.id) {
			zIndex.set(100);
		} else {
			setTimeout(() => {
				zIndex.set(0);
			}, 500);
		}
	}, [router.query.project]);

	const clickHandler = () => {
		router.push("/?project=" + data.name + data.id, undefined, {
			scroll: false,
			shallow: true,
		});
	};
	return (
		<motion.li
			style={{ zIndex }}
			layoutId={"project-modal-" + data.name + data.id}
			{...framerProps}
			custom={index % 6}
			variants={projectAnim}
			className="group bg-white shadow-none hover:shadow-lg cursor-pointer rounded-lg overflow-hidden px-2 !font-heading"
			onClick={clickHandler}
		>
			<div className="w-full h-full relative flex flex-col justify-start items-center">
				<motion.span
					layoutId={"project-img-" + data.name + data.id}
					className="mx-2 mt-2 overflow-hidden rounded-lg relative h-[368px] w-full"
				>
					<Image
						src={data.image}
						fill
						alt={data.name + " spila"}
						quality={100}
						placeholder="blur"
						className="object-cover object-bottom"
						sizes="(max-width: 768px) 100vw, 50vw"
					/>
				</motion.span>
				<motion.div
					layoutId={"project-shadow-" + data.name + data.id}
					className={`w-full flex flex-col z-10 text-black px-2`}
				>
					<motion.h3
						layoutId={"project-name-" + data.name + data.id}
						className="text-xl !font-bold line-clamp-1 text-center mt-4 !font-heading"
					>
						{data.name}
					</motion.h3>
					<motion.p
						layoutId={"project-short-" + data.name + data.id}
						className="text-base font-normal line-clamp-1 text-black/50 mt-3 text-center"
					>
						{data.shortDesc}
					</motion.p>
				</motion.div>
				<motion.ul
					layoutId={"project-hashtags-" + data.name + data.id}
					className="flex gap-2.5 will-change-transform mt-6 mb-4"
				>
					{data.hashtags.map((hashtag, index) => {
						return (
							<motion.li key={index}>
								<Badge
									color={"rgba(129, 127, 134, 0)"}
									intensity={10}
									dotBrightness={1.5}
									textColor="rgba(129, 127, 134, 1)"
									borderColor="rgba(202, 202, 202, 1)"
									borderRadius="9999px"
								>
									{hashtag.name}
								</Badge>
							</motion.li>
						);
					})}
				</motion.ul>
			</div>
		</motion.li>
	);
};

export default EachProject;

import ClientOnly from "hoc/ClientOnly";
import { AnimatePresence, motion } from "framer-motion";
import { MouseEvent, MouseEventHandler, useEffect } from "react";
import Portal from "../Portal";
import { modalAni } from "utils/animation";

interface props {
  isOpen: boolean;
  id: string;
  onBackdropClick: MouseEventHandler<HTMLDivElement> | undefined;
  children: React.ReactNode;
}
const Modal: React.FC<props> = ({ isOpen, id, onBackdropClick, children }) => {
  return (
    <ClientOnly>
      <Portal id={id}>
        <AnimatePresence>
          {isOpen && (
            <motion.div
              initial="hidden"
              animate="visible"
              exit="out"
              variants={modalAni}
              layout
              className="overflow-hidden  fixed top-0 left-0 w-screen h-screen z-[15000] flex justify-center items-center overscroll-none"
              onClick={onBackdropClick}
            >
              {children}
            </motion.div>
          )}
        </AnimatePresence>
      </Portal>
    </ClientOnly>
  );
};

export default Modal;

import { bgImg2 } from "assets/img";
import Image from "next/image";
import Link from "next/link";

const CallToAction: React.FC = ({}) => {
  return (
    <div className="w-full h-72 overflow-hidden mt-32 relative">
      <Image
        alt="spila"
        src={bgImg2}
        quality={100}
        fill
        className="object-cover"
      />
      <div className="absolute top-16 w-full text-center text-[40px] font-heading font-normal text-white">
        <div>
          Make Your Idea True By <span className="font-bold">SPILA</span>
        </div>
        <Link href={"#contact-us"}>
        <button className="mt-8 shadow-primary-50 ring-4 ring-primary-50 rounded-lg bg-white font-bold text-primary-50 text-lg px-8 py-3 hover:scale-105 active:scale-95 duration-300 will-change-transform select-none">
          Get Started
        </button>
        </Link>
      </div>
    </div>
  );
};

export default CallToAction;

import { ArrowUpRightSVG, CloseSVG, ClosecircleSVG } from "assets/svg";
import Badge from "components/common/Badge";
import Button from "components/common/Button";
import Modal from "components/common/Modal";
import { motion } from "framer-motion";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import {
	Dispatch,
	MouseEvent,
	SetStateAction,
	useEffect,
	useState,
} from "react";
import { projectsData } from "store/allData";
import { statusConvertor } from "utils/util";

interface props {
	setIsOpen: Dispatch<SetStateAction<boolean>>;
	isOpen: boolean;
}
const ProjectModal: React.FC<props> = ({ isOpen, setIsOpen }) => {
	const router = useRouter();

	const onBackdropClick = (e: MouseEvent) => {
		const el = e.target as HTMLDivElement;
		e.stopPropagation();
		if (el.parentElement!.matches("#modal")) {
			setIsOpen(false);
			router.push("/", undefined, { scroll: false, shallow: true });
		}
	};

	const activeProject =
		projectsData.find((el) => el.name + el.id === router.query.project) ??
		projectsData[0];

	const modalCloseHandler = () => {
		setIsOpen(false);
		router.push("/", undefined, { scroll: false, shallow: true });
	};
	return (
		<Modal
			id="modal"
			isOpen={isOpen}
			onBackdropClick={(e) => onBackdropClick(e)}
		>
			<motion.div
				layoutId={"project-modal-" + router.query.project}
				className="w-11/12 bg-white rounded-xl !overflow-y-scroll overflow-x-hidden max-w-screen-xl max-h-[85vh] xl:max-h-[55rem] overscroll-none p-4 ssm:p-8 !font-heading"
			>
				<motion.div className="flex flex-col lg:flex-row items-center lg:items-start relative gap-7">
					<span
						className="absolute -top-4 -right-5 w-6 h-6 fill-neutral-50 z-100 cursor-pointer hover:fill-neutral-40 duration-300
          rounded-full bg-white"
						onClick={modalCloseHandler}
					>
						<ClosecircleSVG />
					</span>
					<motion.span
						layoutId={"project-img-" + router.query.project}
						className="overflow-hidden rounded-lg relative w-full h-[400px] md:h-[531px] lg:w-[368px] "
					>
						<Image
							src={activeProject.image}
							alt={activeProject.name + " spila"}
							fill
							quality={100}
							placeholder="blur"
							className="object-cover object-bottom"
							sizes="(max-width: 768px) 100vw, 50vw"
						/>
					</motion.span>

					<motion.div
						layoutId={"project-shadow-" + router.query.project}
						className="w-10/12 lg:w-[calc(100%-398px)] flex flex-col z-10  !font-heading
           text-black"
					>
						<div className="flex gap-3 flex-col lg:flex-row">
							<div className="flex flex-col">
								<motion.h3
									layoutId={"project-name-" + router.query.project}
									className="text-2xl text-black break-words !font-bold !font-heading"
								>
									{activeProject.name}
								</motion.h3>
								<motion.h3
									layoutId={"project-short-" + router.query.project}
									className="text-base mt-1 text-[#18171B] break-words !font-normal"
								>
									{activeProject.shortDesc}
								</motion.h3>
							</div>
							<motion.ul
								layoutId={"project-hashtags-" + router.query.project}
								className="flex gap-2.5 will-change-transform "
							>
								{activeProject.hashtags.map((hashtag, index) => {
									return (
										<motion.li key={index}>
											<Badge
												color={"rgba(129, 127, 134, 0)"}
												intensity={10}
												dotBrightness={1.5}
												textColor="rgba(129, 127, 134, 1)"
												borderColor="rgba(202, 202, 202, 1)"
												borderRadius="9999px"
											>
												{hashtag.name}
											</Badge>
										</motion.li>
									);
								})}
							</motion.ul>
						</div>

						<motion.p className="text-[#18171B] text-base font-medium z-20 mt-7 min-h-[5rem]">
							{activeProject.desc}
						</motion.p>
						{activeProject.link && (
							<Link
								href={activeProject.link}
								target="_blank"
								className="mt-8 w-fit"
							>
								<Button className=" flex gap-1.5" size={2} variant="primary">
									Visit the Product <ArrowUpRightSVG className="w-6 h-6" />
								</Button>
							</Link>
						)}
						<div className="flex items-center gap-x-5 mt-3">
							<span className="text-sm font-bold whitespace-nowrap text-[#817F86]">
								More to know
							</span>
							<span className="w-full h-px bg-neutral-50/20" />
						</div>

						<span className="flex flex-col mt-3">
							<h3 className="text-base font-bold">Technologies:</h3>
							<p className="text-[#18171B] text-base font-medium mt-3">
								{activeProject.techDesc}
							</p>
							<ul className="flex gap-2 flex-wrap mt-5">
								{activeProject?.techs.map((data) => {
									return (
										<Badge
											key={data.name}
											color={"rgba(244, 244, 244, 1)"}
											intensity={10}
											dotBrightness={1.5}
											textColor="rgba(129, 127, 134, 1)"
											borderColor="rgba(202, 202, 202, 0)"
											borderRadius="9999px"
										>
											{data.name}
										</Badge>
									);
								})}
							</ul>
						</span>
					</motion.div>
				</motion.div>
			</motion.div>
		</Modal>
	);
};

export default ProjectModal;

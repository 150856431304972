import { benefitData } from "store/allData";
import EachBenefit from "./EachBenefit";
import SectionWrapper from "components/common/SectionWrapper";

const Benefits: React.FC = () => {
	return (
		<SectionWrapper className="mx-auto z-20">
			<ul
				id="benefits"
				className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 mx-auto mt-44 mb-32 gap-3"
			>
				{benefitData.map((data) => {
					return <EachBenefit key={data.title} data={data} />;
				})}
			</ul>
		</SectionWrapper>
	);
};

export default Benefits;

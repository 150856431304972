import { badgeType } from "types/allTypes";
import { hexToRgbA } from "utils/util";

interface props {
  /**
   *
   * data include name and color, name is the text inside badge
   * and  color is a hex that transform to 3 other colors
   */
  color: string;
  /**
   * intensity define how much color should be darker
   * it accepts numbers from 0 to 10
   * and 10 is completely darker one
   */
  intensity?: number;
  /**
   * if the contrast of text and background are not fine, you can use this property to define another color for text
   */
  textColor?: string | undefined;
  /**
   * if the text brightness is not fine you can use this property, it accepts from 0 to 2
   */
  textBrightness?: number;
  /**
   * if the dot brightness is not fine you can use this property, it accepts from 0 to 2
   */
  dotBrightness?: number;
  hasDot?: boolean;
  borderColor?:string;
  children:React.ReactNode;
  borderRadius?:string;
}

const Badge: React.FC<props> = ({
  color,
  intensity = 1,
  textColor,
  textBrightness = 1,
  dotBrightness = 1,
  hasDot,
  borderColor,
  children,
  borderRadius="8px",
}) => {

  return (
    <div
      className="flex gap-x-1 justify-center items-center px-8 py-2 w-fit duration-300 border"
      style={{
        backgroundColor: hasDot ? hexToRgbA(color, 0.1 * intensity) : color,
        borderColor: borderColor ?? "transparent",
        borderRadius
      }}
    >
      {hasDot && (
        <span
          className="w-3 h-3 rounded-full"
          style={{
            backgroundColor: hexToRgbA(color, 0.3 * intensity),
            filter: `brightness(${dotBrightness})`,
          }}
        />
      )}
      <span
        className="text-xs font-medium select-none whitespace-nowrap"
        style={{
          color: textColor ? textColor : hexToRgbA(color),
          filter: textColor ? "" : `brightness(${textBrightness})`,
        }}
      >
        {children}
      </span>
    </div>
  );
};

export default Badge;

interface props {
	variant: "primary" | "secondary" | "outline" | "tertiary";
	size: number;
	clickHandler?: () => void;
	children: React.ReactNode;
	className?: string;
	disabled?: boolean;
}

const Button: React.FC<props> = ({
	children,
	variant,
	size,
	clickHandler,
	className,
	disabled,
}) => {
	return (
		<button
			onClick={clickHandler}
			className={`button-container btn-${variant} ${className}`}
			disabled={disabled}
			style={{
				paddingInline: size * 1 + "rem",
				paddingBlock: size * 0.5 + "rem",
				fontSize: size * 0.56 + "rem",
			}}
		>
			{children}
		</button>
	);
};

export default Button;

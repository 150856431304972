import HomePage from "components/HomePage";
import Head from "next/head";
import { Fragment } from "react";
import { teamData, projectsData, homePageMetaData } from "store/allData";
import shuffle from 'lodash/shuffle';
import {GetStaticProps, InferGetStaticPropsType} from 'next';
export default function home({ teamDataGridOrder,projectsDataGridOrder }:InferGetStaticPropsType<typeof getStaticProps>) {

  return (
    <Fragment>
      <Head>
        <title>{"Spila" + " | " + homePageMetaData.pageTitle}</title>
        <meta charSet="utf-8" />
        <meta name="description" content={homePageMetaData.desc} />
        <meta name="keywords" content={homePageMetaData.keywords} />
        <link rel="canonical" href={homePageMetaData.pageUrl} />
        <meta property="og:url" content={homePageMetaData.pageUrl} />
        <meta property="og:image" content={homePageMetaData.imageUrl} />
        <meta property="og:description" content={homePageMetaData.desc} />
        <meta
          property="og:title"
          content={
            homePageMetaData.siteTitle + " | " + homePageMetaData.pageTitle
          }
        />
        <meta property="og:site_name" content={homePageMetaData.siteTitle} />
        <meta property="og:see_also" content={homePageMetaData.homePageUrl} />
        <meta name="twitter:card" content={"summary"} />
        <meta name="twitter:url" content={homePageMetaData.pageUrl} />
        <meta name="twitter:title" content={homePageMetaData.pageTitle} />
        <meta name="twitter:description" content={homePageMetaData.desc} />
        <meta name="twitter:image" content={homePageMetaData.imageUrl} />
        <meta property="og:image:width" content="512" />
        <meta property="og:image:height" content="512" />
        <meta property="og:image:type" content="image/jpg" />
        <meta
          property="og:image:secure_url"
          content={homePageMetaData.imageUrl}
        />
      </Head>



      
      <HomePage teamDataGridOrder={teamDataGridOrder} projectsDataGridOrder={projectsDataGridOrder} />
    </Fragment>
  );
}

export const getStaticProps:GetStaticProps = () => {
  const teamGridOrderList = Array.from(Array(teamData.length).keys());
  const shuffledTeamGridOrderList = shuffle(teamGridOrderList);

  const projectsGridOrderList = Array.from(Array(projectsData.length).keys());
  const shuffledProjectsGridOrderList = shuffle(projectsGridOrderList);

  return {
    props: {
      teamDataGridOrder: shuffledTeamGridOrderList,
      projectsDataGridOrder: shuffledProjectsGridOrderList,
    },
    revalidate: 1
  }
}